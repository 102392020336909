/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 20px;
}

/* Color/shape of burger icon bars */
/*.bm-burger-bars {*/
/*  background: #232324;*/
/*}*/

/*!* Position and sizing of clickable cross button *!*/
/*.bm-cross-button {*/
  /*height: 50px !important;*/
  /*width: 50px !important;*/
/*}*/

/* Color/shape of close button cross */
.bm-cross {
  background: #6e7478;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  /*padding: 2.5em 1.5em 0;*/
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin-top: 5px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.navbar > .container > .dropdown {
  display: inline;
  top: -25px;
  left: 10px;
}

a.bm-item {
  margin: 5px 10px 5px 15px;
  color: #828179;
}

a.bm-item > span {
  margin-left: 8px;
}

.bm-item a {
  display: block;
  margin: 5px 10px 5px 15px;
  color: #828179;
}

.bm-item a > span {
  margin-left: 8px;
}

