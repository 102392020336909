html, body {
  height: 100%;
}

#root {
    min-height: 100%;
    height: 100%;
}

.navbar {
    margin-bottom: 5px;
}

#root [data-reactroot] {
    height: 100% !important;
}

#root [data-reactroot] #wrap {
    min-height: 100%;
    height: auto !important;
}

#root [data-reactroot] #wrap #main {
  overflow: visible;
  padding-bottom: 60px; /* this needs to be bigger than footer height*/
}

.footer {
  position: relative;
  margin-top: -60px; /* negative value of footer height */
  height: 60px;
  clear: both;
  padding-top: 5px;
}

.page-title {
    background-color: #ffffff;
    padding: 10px;
}

.page-title .additional-info {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.form-element {
    margin-bottom: 20px;
}

.form-element p {
    margin: 0;
}

.content-background {
    background-color: #ffffff;
}

.content-padding {
    padding: 10px
}

.a-disable-decoration:hover {
    text-decoration: none;
}

img.navbar-brand {
    padding: 0;
}

.loader-inner {
    display: inline-block;
}

.highlight-on-hover:hover { background-color: #ddd; }
.highlight-border-on-hover:hover { border-color: #2196f3 !important; }

.search-result:hover { background-color: #ddd; }
.search-result .thumbnail { border-radius: 0 !important; }
.search-result { margin-bottom: 0 !important; padding-top: 15px; padding-bottom: 15px; border-bottom: 1px solid #ddd; }
.search-result ul { padding-left: 0 !important; list-style: none; margin-bottom: 0 !important; }
/*.search-result ul li { font: 400 normal .85em "Roboto",Arial,Verdana,sans-serif;  line-height: 30px; }*/
.search-result ul li i { padding-right: 5px; }
.table-hover-white tbody tr:hover {
    background-color: #ffffff
}

.card {
    padding: 30px;
    background-color: rgba(214, 224, 226, 0.2);
    -webkit-border-top-left-radius:5px;
    -moz-border-top-left-radius:5px;
    border-top-left-radius:5px;
    -webkit-border-top-right-radius:5px;
    -moz-border-top-right-radius:5px;
    border-top-right-radius:5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.card.hovercard {
    position: relative;
    padding-top: 0;
    overflow: hidden;
    text-align: center;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 1);
}
.card.hovercard .card-background {
    height: 230px;
}
.card-background img {
    -webkit-filter: blur(25px);
    -moz-filter: blur(25px);
    -o-filter: blur(25px);
    -ms-filter: blur(25px);
    filter: blur(25px);
    margin-left: -100px;
    margin-top: -200px;
    min-width: 130%;
}
.card.hovercard .logo {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
}
.card.hovercard .logo img {
    width: 200px;
    height: 200px;
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
    object-position: center;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.card.hovercard .card-info {
    position: absolute;
    bottom: 14px;
    left: 0;
    right: 0;
}
.card.hovercard .card-info .card-title {
    padding: 10px;
    font-size: 20px;
    line-height: 1.5;
    color: #262626;
    background-color: rgba(255, 255, 255, 0.5);
}
.card.hovercard .card-info {
    overflow: hidden;
    font-size: 12px;
    line-height: 20px;
    color: #737373;
    text-overflow: ellipsis;
}

a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}

.navbar > .container > .navbar-collapse > .navbar-nav > li > a {
    margin-left: 0;
    margin-right: 0;
}

.navbar > .container > .navbar-collapse > .navbar-nav > li > a:hover,#user-dropdown {
    border: none !important;
}

.navbar > .container > .navbar-collapse > .navbar-nav > li.dropdown.open > a {
    border: none !important;
}

.navbar > .container > .navbar-collapse > .navbar-nav > li.active > a {
    border: none !important;
}

.navbar > .container > .navbar-collapse > .navbar-nav > li:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
